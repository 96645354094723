import {
  NumberInput,
  ReferenceInput,
  SimpleForm,
  TextInput,
  EditButton,
  required,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  minValue,
  TopToolbar,
  CreateButton,
  useRedirect,
  InputProps,
  useRecordContext,
  TextField,
} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { IconButton } from '@mui/material';
import SensorsOffIcon from '@mui/icons-material/SensorsOff';
import { ListLive } from '@react-admin/ra-realtime';
import { useTranslate } from 'ra-core';
import _ from 'lodash';
import { CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import SimpleChipListField from '../../../components/fields/SimpleChipListField';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { FullExportButton } from '../../../utils/customExporter';
import NumberFieldNullable from '../../../components/fields/NumberFieldNullable';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const transform = (data: Record<string, any>) => {

  const zone = data.zone?._id;
  delete data.zone;
  return {
    ...data,
    ...(zone ? { zone: { _ref: 'Zone', _id: zone } } : {}),
    type: 'default',
    ...(data.codes ? { codes: data.codes.map((code: { name: string }) => code.name.toLowerCase()) } : {}),
  };

};

const shareTypes = [
  { name: 'resources.beacons.text.share_types.public', id: 'publicRead' },
  { name: 'resources.beacons.text.share_types.default', id: 'parents' },
];

const BeaconActions = () => {

  const redirect = useRedirect();

  return (
    <TopToolbar>
      <IconButton onClick={() => redirect('/reports/unhealthy-beacons')}>
        <SensorsOffIcon color="secondary"/>
      </IconButton>
      <CreateButton/>
      <FullExportButton resource={'beacons'}/>
    </TopToolbar>
  );

};

const BeaconLastSeenField = (props: InputProps) => {

  const record = useRecordContext();
  const date = _.get(record, props.source);
  const now = new Date();
  const translate = useTranslate();
  const lastSeen = new Date(date);
  if (now.getTime() - lastSeen.getTime() < 1000 * 60 * 60) {

    return <TextField source="input" record={{ input: translate('resources.beacons.text.in_last_hour') }} label="resouces.beacons.fields.state.lastSeenAt" />;

  }
  return <DateFieldNullable {...props} showTime={true} />;

};

export const BeaconList = () => {

  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable} />
      <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} title="resources.beacons.text.title"
        actions={<BeaconActions/>}
      >
        <>
          <FilterResetter/>
          <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons />}>
            <SimpleChipListField source="codes"/>
            <NumberFieldNullable source="rssiAddend"/>
            <ReferenceFieldNullable source="zone._id" reference="zones"/>
            <BeaconLastSeenField source="state.lastSeenAt"/>
            <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
            <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
            <HasRoles anyOf={['developer_admin']}>
              <AuditlogButton/>
            </HasRoles>
            <IfCanAccess action="edit">
              <EditButton/>
            </IfCanAccess>
          </Datagrid>
        </>
      </ListLive>
    </>);

};

export const BeaconEdit = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable} />
    <EditWithSuccess transform={transform} title="resources.beacons.text.title">
      <SimpleForm toolbar={<CustomToolbar/>}>
        <SelectInput source="share.type" choices={shareTypes} defaultValue={'publicRead'} validate={required()}/>
        <ArrayInput source="codes" defaultValue={['']}>
          <SimpleFormIterator inline disableReordering>
            <TextInput source="name"
              helperText={false}
              label="resources.beacons.text.code"
              inputProps={{ style: { textTransform: 'lowercase' } }}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="rssiAddend" validate={[required(), minValue(0)]}/>
        <ReferenceInput source="zone._id" reference="zones" sort={{ field: 'name', order: 'ASC' }} />
      </SimpleForm>
    </EditWithSuccess>
  </>
);
export const BeaconCreate = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable} />
    <CreateWithSuccess transform={transform} title="resources.beacons.text.title">
      <SimpleForm>
        <SelectInput source="share.type" choices={shareTypes} defaultValue={'publicRead'} validate={required()}/>
        <ArrayInput source="codes" defaultValue={[undefined]}>
          <SimpleFormIterator inline disableReordering>
            <TextInput source="name"
              helperText={false}
              label="resources.beacons.text.code"
              inputProps={{ style: { textTransform: 'lowercase' } }}
            />
          </SimpleFormIterator>
        </ArrayInput>
        <NumberInput source="rssiAddend" validate={[required(), minValue(0)]}/>
        <ReferenceInput source="zone._id" reference="zones" sort={{ field: 'name', order: 'ASC' }} />
      </SimpleForm>
    </CreateWithSuccess>
  </>
);
