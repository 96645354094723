import {
  Circle, Marker, Popup, useMapEvents,
} from 'react-leaflet';
import * as React from 'react';
import {
  MutableRefObject,
  SetStateAction, useEffect, useRef, useState,
} from 'react';
import { Identifier, useNotify, useTranslate } from 'react-admin';
import { Asset, Device } from '@x-guard/xgac-types/xgac';
import L from 'leaflet';
import { renderToString } from 'react-dom/server';
import { xgacDataProvider } from '../../../dataProviders/xgacDataProvider';
import { StyledButton } from '../buttons/StyledButton';
import defaultButtonIcon from '../svg/button_default.svg';
import selectedButtonIcon from '../svg/button_selected.svg';
import defaultGatewayIcon from '../svg/gateway_default.svg';
import selectedGatewayIcon from '../svg/gateway_selected.svg';
import { httpClient } from '../../../utils/httpClient';
import { XGAC_MAIN_API_URL } from '../../../config';
import { getCurrentCustomer } from '../../../lib/currentCustomer';

const apiUrl = XGAC_MAIN_API_URL;

const zoomLevelOpacityMapping = {
  20: 0.3,
  21: 0.2,
  22: 0.1,
};

const getIcon = (iconSvg: string, label: string, mapTileLayer: string, markerId: string) => {

  const icon = new L.DivIcon({
    html: renderToString(<div id={`marker-${markerId}`}>
      <img src={iconSvg} style={{ width: '30px' }} alt="marker"/>
      <div style={{
        width: '100px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        position: 'relative',
        top: '-5px',
        left: '-34px',
        textAlign: 'center',
        fontSize: '10.5px',
        color: mapTileLayer === 'Satellite' ? 'white' : 'black',
        textShadow: mapTileLayer === 'Satellite' ? '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000' : 'none',
      }}>{label}</div>
    </div>),
    iconSize: [40, 61],
    popupAnchor: [-5, -46],
    iconAnchor: [18, 46],
  });

  return icon;

};

export const ButtonMapMarker = (props: {
  item: Asset & { device: Device };
  expandedId: Identifier;
  setExpandedId: SetStateAction<any>;
  toggleItemExpansion: any;
  mapTileLayer: string;
  createAlarmMode?: boolean;
  moveLocked: boolean;
  setCreateAlarmOpen?: React.Dispatch<React.SetStateAction<any>>;
 }) => {

  const circleRef = useRef();
  const translate = useTranslate();
  const markerRef:MutableRefObject<any> = useRef();
  const notify = useNotify();

  useEffect(() => {

    if (!circleRef || !circleRef.current) {

      return;

    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    circleRef.current._path.style.opacity = 0.4;

  }, [circleRef]);

  useEffect(() => {

    if (!circleRef || !circleRef.current) {

      return;

    }
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const svg = circleRef.current.getElement()?.farthestViewportElement;
    if (!svg) return;
    const defs = svg.querySelector('defs') || svg.insertBefore(document.createElementNS(svg.namespaceURI, 'defs'), svg.firstChild);
    if (!defs) return;
    function createGradient(svgElem: any, id: string, stops: any) {

      const radGrad = document.createElementNS(svg.namespaceURI, 'radialGradient');
      radGrad.setAttribute('id', id);
      for (const s of stops) {

        const stop = document.createElementNS(svg.namespaceURI, 'stop');
        for (const k in s) {

          stop.setAttribute(k, s[k]);

        }
        radGrad.appendChild(stop);

      }
      defs.appendChild(radGrad);

    }
    createGradient(svg, 'radgrad', [
      { offset: '0%', 'stop-color': 'rgba(0, 133, 33, 1)' },
      { offset: '20%', 'stop-color': 'rgba(0, 133, 33,  1)' },
      { offset: '90%', 'stop-color': 'rgba(0, 133, 33, 0.1)' },
      { offset: '95%', 'stop-color': 'rgba(0, 133, 33, 0)' },
    ]);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    circleRef.current.setStyle({
      fillColor: 'url(#radgrad)',
    });

  }, [circleRef]);

  const dragEndUpdateMarker = (item: Asset, coordinates: [number, number]) => {

    const currentCustomer = getCurrentCustomer()?.value;
    xgacDataProvider.update('assets', {
      id: item._id,
      data: {
        position: {
          ...item.position,
          coordinates: [coordinates[1], coordinates[0]],
        },
      },
      previousData: item,
    }).finally(() => {

      if (currentCustomer) {

        httpClient(
          `${apiUrl}/specials/bhv-knop/update-monitored-zones/`,
          { method: 'POST' },
          { customer: { _id: currentCustomer, _ref: 'Customer' } },
        );

      }

    });

  };

  const [position, setPosition] = useState<[number, number]>(
    [props.item.position?.coordinates[1] || 0, props.item.position?.coordinates[0] || 0],
  );

  useEffect(() => {

    setPosition([props.item.position?.coordinates[1] || 0, props.item.position?.coordinates[0] || 0]);

  }, [props.item]);

  useEffect(() => {

    // update the markerRef eventHandler dragend
    if (markerRef.current) {

      markerRef.current.off('dragend');
      if (!props.moveLocked) {

        markerRef.current.on('dragend', async (e: any) => {

          const { lat, lng } = e.target.getLatLng();
          setPosition([lat, lng]);
          await dragEndUpdateMarker(props.item, [lat, lng]);

        });

      } else {

        markerRef.current.on('dragend', async () => {

          setPosition([props.item.position?.coordinates[1] || 0, props.item.position?.coordinates[0] || 0]);
          markerRef.current.setLatLng([props.item.position?.coordinates[1] || 0, props.item.position?.coordinates[0] || 0]);
          notify('resources.button.text.move_locked', { type: 'warning' });

        });

      }

    }

  }, [props.moveLocked, props.item]); // eslint-disable-line react-hooks/exhaustive-deps

  const currentIcon = () => {

    const formattedExternalId = props.item.externalId?.split('-')?.slice(-1)[0];
    const formattedLabel = formattedExternalId
      ? `${props.item.name} (${translate(props.item.device.type === 'lora' ? 'general.text.button' : 'general.text.gateway')} ${formattedExternalId})`
      : props.item.name;
    if (props.item.device.type === 'loraGateway') {

      if (props.item._id === props.expandedId) {

        return getIcon(selectedGatewayIcon, formattedLabel, props.mapTileLayer, props.item._id);

      }
      return getIcon(defaultGatewayIcon, formattedLabel, props.mapTileLayer, props.item._id);

    }
    if (props.item._id === props.expandedId) {

      return getIcon(selectedButtonIcon, formattedLabel, props.mapTileLayer, props.item._id);

    }

    return getIcon(defaultButtonIcon, formattedLabel, props.mapTileLayer, props.item._id);

  };

  if (!props.item.position) return null;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const mapEvents = useMapEvents({

    zoomend: () => {

      if (circleRef.current) {

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        circleRef.current._path.style.opacity = zoomLevelOpacityMapping[mapEvents.getZoom()] || 0.4;

      }

    },

  });

  return (
    <Marker
      key={props.item._id}
      position={position}
      ref={markerRef}
      eventHandlers={{
        click: () => {

          props.setExpandedId(props.item._id);
          props.toggleItemExpansion(props.item._id);

        },
      }}
      draggable={true}
      icon={currentIcon()}
    >
      <Popup>
        <div>
          <h4>{props.item.name}</h4>
          <p>{props.item.position.properties?.address?.formattedAddress}</p>
          {props.createAlarmMode && <StyledButton label={'Maak alarm'} onClick={() => {

            if (!props.setCreateAlarmOpen) return;

            props.setCreateAlarmOpen(props.item);

          }} />}
        </div>
      </Popup>
      {props.item?.device?.type === 'loraGateway' && (
        <>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/*  @ts-ignore */}
          <Circle ref={circleRef}
            center={position}
            radius={100}
            weight={0}
            fillOpacity={1}
          />
        </>
      )}
    </Marker>
  );

};
