import {
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  Datagrid,
  EditButton,
  List,
  maxValue,
  minValue,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SimpleForm,
  TextField,
} from 'react-admin';
import React from 'react';
import { IfCanAccess } from '@react-admin/ra-rbac';
import SearchFields from '../components/fields/Filters';
import { LIST_DEBOUNCE } from '../../../config';
import { exporter } from '../../../utils/customExporter';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { CustomToolbar } from '../../../components/CustomToolBar';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const transform = (data: any) => {

  return {
    ...data,
    alarmCenter: {
      _id: data.alarmCenter._id,
      _ref: 'Customer',
    },
    zones: data.zones || [],
  };

};

export const CustomerAlarmCenterList = () => {

  return (
    <List filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.customer-alarm-center-relations.title">
      <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
        <BooleanField source={'enabled'}/>
        <TextField source={'priority'}/>
        <ReferenceField reference="customers" source={'alarmCenter._id'} label="resources.customers.fields.alarmCenter_name"/>
        <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
        <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
        <HasRoles anyOf={['developer_admin']}>
          <AuditlogButton/>
        </HasRoles>
        <IfCanAccess action="edit">
          <EditButton/>
        </IfCanAccess>
      </Datagrid>
    </List>
  );

};

export const CustomerAlarmCenterEdit = () => {

  return (
    <EditWithSuccess title="resources.customer-alarm-center-relations.title" transform={transform}>
      <SimpleForm toolbar={<CustomToolbar/>}>
        <NumberInput source={'priority'}
          validate={[required(),
            minValue(1),
            maxValue(10)]}/>
        <ReferenceInput reference="customers" source={'alarmCenter._id'}
          filter={{ 'alarmCenter.enabled': true }}
          sort={{ field: 'name', order: 'ASC' }}

        >
          <AutocompleteInput label="resources.customers.fields.alarmCenter_name" validate={required()}/>
        </ReferenceInput>
        <BooleanInput source={'enabled'}/>
      </SimpleForm>
    </EditWithSuccess>
  );

};

export const CustomerAlarmCenterCreate = () => {

  return (
    <CreateWithSuccess title="resources.customer-alarm-center-relations.title" transform={transform}>
      <SimpleForm>
        <NumberInput source={'priority'}
          validate={[required(),
            minValue(1),
            maxValue(10)]}/>
        <ReferenceInput reference="customers" source={'alarmCenter._id'}
          filter={{ 'alarmCenter.enabled': true }}
          sort={{ field: 'name', order: 'ASC' }}
        >
          <AutocompleteInput label="resources.customers.fields.alarmCenter_name" validate={required()}/>
        </ReferenceInput>
        <BooleanInput source={'enabled'} defaultValue={true}/>
      </SimpleForm>
    </CreateWithSuccess>
  );

};
