import {
  SimpleForm,
  TextInput,
  EditButton,
  required,
  useTranslate,
} from 'react-admin';
import {
  Datagrid, IfCanAccess,
} from '@react-admin/ra-rbac';
import * as React from 'react';
import { Typography } from '@mui/material';
import { ListLive } from '@react-admin/ra-realtime';
import { CustomToolbar } from '../../../components/CustomToolBar';
import SearchFields, { FilterResetter } from '../components/fields/Filters';
import ActiveBooleanField from '../../../components/fields/ActiveBooleanField';
import CustomBulkActionButtons from '../../../components/buttons/CustomBulkActionButtons';
import { exporter } from '../../../utils/customExporter';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';
import { CreateWithSuccess } from '../../../components/baseForms/CreateWithSuccess';
import { LIST_DEBOUNCE } from '../../../config';
import { StabilityAlert, StabilityLevel } from '../../../components/StabilityAlert';
import { EditWithSuccess } from '../../../components/baseForms/EditWithSuccess';
import { PhoneNumberInput } from '../../../components/inputs/PhoneNumberInput';
import { DateFieldWithTime } from '../../../components/fields/DateFieldWithTime';
import { ScheduleInput } from '../../../components/inputs/ScheduleInput';
import { HasRoles } from '../../../components/HasRoles';
import { AuditlogButton } from '../components/buttons/AuditlogButton';

const transform = (data: Record<string, any>) => {

  delete data.share;
  return {
    ...data,
    externalId: data.externalId || null,
    contact: {
      ...data.contact,
      email: data.contact.email || null,
      camera: data.contact.camera || null,
      sms: data.contact.sms || null,
      phone: data.contact.phone || null,
    },
  };

};

export const StaticResponderList = () => (
  <>
    <StabilityAlert stability={StabilityLevel.Stable}/>
    <ListLive filters={SearchFields} debounce={LIST_DEBOUNCE} exporter={exporter} title="resources.static-responders.text.title">
      <>
        <FilterResetter/>
        <Datagrid rowClick="toggleSelection" bulkActionButtons={<CustomBulkActionButtons/>}>
          <TextFieldNullable source="name" label="general.fields.name"/>
          <TextFieldNullable source="contact.email" label="resources.assets.fields.email" privacy/>
          <TextFieldNullable source="contact.phone" label="resources.assets.fields.phoneNumber" privacy/>
          <TextFieldNullable source="contact.sms" label="resources.assets.fields.smsNumber" privacy/>
          <TextFieldNullable source="contact.camera" privacy/>
          <TextFieldNullable source="externalId" privacy/>
          <ActiveBooleanField source="schedule" label="resources.static-responders.fields.schedule.name" />
          <DateFieldWithTime source="createdAt" label="general.fields.createdAt" timeOnHover={true}/>
          <DateFieldWithTime source="updatedAt" label="general.fields.updatedAt" timeOnHover={true}/>
          <HasRoles anyOf={['developer_admin']}>
            <AuditlogButton/>
          </HasRoles>
          <IfCanAccess action="edit">
            <EditButton/>
          </IfCanAccess>
        </Datagrid>
      </>
    </ListLive>
  </>
);

export const StaticResponderEdit = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <EditWithSuccess transform={transform} title="resources.static-responders.text.title">
        <SimpleForm toolbar={<CustomToolbar/>}>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.contact_options')}
          </Typography>
          <TextInput source="externalId"/>
          <TextInput source="contact.email" label="resources.assets.fields.email"/>
          <PhoneNumberInput source="contact.phone" label="resources.assets.fields.phoneNumber"/>
          <PhoneNumberInput source="contact.sms" label="resources.assets.fields.smsNumber"/>
          <TextInput source="contact.camera"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.availability')}
          </Typography>
          <ScheduleInput source={'schedule.entries'}/>
        </SimpleForm>
      </EditWithSuccess>
    </>
  );

};

export const StaticResponderCreate = () => {

  const translate = useTranslate();
  return (
    <>
      <StabilityAlert stability={StabilityLevel.Stable}/>
      <CreateWithSuccess transform={transform} title="resources.static-responders.text.title">
        <SimpleForm>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.general')}
          </Typography>
          <TextInput source="name" validate={required()} label="general.fields.name"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.contact_options')}
          </Typography>
          <TextInput source="externalId"/>
          <TextInput source="contact.email" label="resources.assets.fields.email"/>
          <PhoneNumberInput source="contact.phone" label="resources.assets.fields.phoneNumber"/>
          <PhoneNumberInput source="contact.sms" label="resources.assets.fields.smsNumber"/>
          <TextInput source="contact.camera"/>
          <Typography variant="h6" gutterBottom>
            {translate('resources.static-responders.text.availability')}
          </Typography>
          <ScheduleInput source={'schedule.entries'}/>
        </SimpleForm>
      </CreateWithSuccess>
    </>
  );

};
