import {
  GetListParams, Identifier, SortPayload,
} from 'react-admin';
import queryString from 'query-string';
import _ from 'lodash';
import type {
  Asset,
  Alarm,
  Customer,
  StaticResponderRelation,
  Zone,
  ContactListRef,
  AssetGroupRef,
  CustomerRef,
  AssetRef,
  PointGeometry, Device,
} from '@x-guard/xgac-types/xgac';
import { SubscriptionCallback } from '@react-admin/ra-realtime';
import * as turf from '@turf/turf';
import {
  ATS_CUSTOMER_LINK_RED_ORANGE_WITH_ALARMCENTER_TEMPLATE_ID,
  ATS_CUSTOMER_LINK_RED_ORANGE_WITHOUT_ALARMCENTER_TEMPLATE_ID,
  XGAC_MAIN_API_URL,
} from '../config';
import { httpClient } from '../utils/httpClient';
// eslint-disable-next-line import/no-cycle
import { customerCreateDefaults } from '../utils/customerCreateDefaults';
import {
  RaXgacRecord, RecordNameAsRaXgacRecord,
} from '../lib/RaXgacRecord';
import { TypedDataProvider } from './typedDataProvider';
import { updateComparison } from './updateComparison';
import {
  toXgacCreateDto, toXgacUpdateDto,
} from './XgacDto';
import {
  afterTranslateResource, preTranslateResource,
} from './resourceTranslators';
import { WebSocketService } from './xgacWebSocket';
import { getCurrentCustomer } from '../lib/currentCustomer';
// eslint-disable-next-line import/no-cycle
import { qrCodeDataProvider } from './qrCodeDataProvider';

const apiUrl = XGAC_MAIN_API_URL;

const socketService = new WebSocketService();

function translateListParams(resource: string, params: GetListParams) {

  if (params.filter && params.filter.assetGroups) {

    params.filter['assetGroups._id'] = params.filter.assetGroups;
    delete params.filter.assetGroups;

  }
  if (params.filter && params.filter.zoneIds) {

    params.filter['zone._id'] = params.filter.zoneIds;
    delete params.filter.zoneIds;

  }
  if (params.filter && params.filter.beaconCode) {

    params.filter.beaconCodes = [`/${params.filter.beaconCode}/`];
    delete params.filter.beaconCode;

  }
  if (resource === 'beacons') {

    params = {
      ...params,
      filter: {
        ...params.filter,
        type: 'default',
      },
    };

  }
  if (resource === 'app-users') {

    params = {
      ...params,
      filter: {
        ...params.filter,
        app: '!null',
      },
    };

  }
  if (resource === 'assets') {

    params = {
      ...params,
      filter: {
        ...params.filter,
        app: 'null',
      },
    };

  }
  return params;

}

async function sendPicture(image: any, entityId: string, entityType = 'assets') {

  const formData = new FormData();
  formData.append('photo_url', image);
  const imageRequest = await httpClient(`${apiUrl}/${entityType}/app/photo/?id=${entityId}`, { method: 'POST', body: formData });
  return JSON.parse(imageRequest.body).image;

}

function getPointsAroundPosition(steps: number, position: PointGeometry): PointGeometry['coordinates'][] {

  const circle = turf.circle(
    turf.point(position.coordinates),
    0.01 * steps,
    {
      steps: Math.max(steps, 4),
      units: 'kilometers',
    },
  );

  // get the points
  return turf.getCoords(circle)[0].slice(0, steps);

}

export const xgacDataProvider: TypedDataProvider = {
  getList: async (resource, params) => {

    if (params.pagination?.perPage === -1) {

      return xgacDataProvider.getAll(resource, params);

    }
    params = translateListParams(resource, params);
    if (!['users/without-app', 'devices/without-app', 'service-accounts'].includes(resource)) {

      resource = preTranslateResource(resource);

    }

    if (!params.pagination) {

      params.pagination = { page: 1, perPage: 100 };

    }
    if (!params.sort) {

      params.sort = { field: 'createdAt', order: 'DESC' };

    }
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const customer = params.meta?.customer || getCurrentCustomer();
    const filter = params.filter;
    const betweenFilter = filter.createdAt_between;
    delete filter.createdAt_between;
    Object.keys(filter).forEach((key) => {

      const splitKey = key.split('_');
      if (splitKey.length > 1 && splitKey[0] === 'in') {

        filter[splitKey[1]] = `/${filter[key]}/`;
        delete filter[key];

      }

    });

    let query = {
      $offset: (page - 1) * perPage,
      $max: perPage,
      $sort: `${resource === 'alarms' ? 'ack.value,' : ''}${((order === 'DESC') ? '-' : '')}${((field === 'id') ? '_id' : field)}`,
      ...(!['users', 'customers', 'responsePartners', 'service-accounts', 'roles'].includes(resource))
        ? { 'customer._id': customer.value } : {},
      ...(resource === 'assets') ? { app: 'null' } : {},
      ...(resource === 'responsePartners') ? { 'responsePartner.enabled': true } : {},
      ...(resource === 'service-accounts') ? { isServiceAccount: true } : {},
      ...(resource === 'fingerprints' ? { $select: 'zone,deviceInfo,enabled,description,createdAt,updatedAt,dataCount,user' } : {}),
      ...filter,
    };
    if (params.meta?.$select) {

      query.$select = params.meta.$select;

    }

    if (resource === 'zones' && params.meta?.includeFingerprints) {

      query = {
        ...query,
        includeFingerprints: true,
      };

    }

    if (resource === 'alarms') {

      const contextString = localStorage.getItem('context');

      if (contextString) {

        const context = JSON.parse(contextString);

        const customers = context.customerTree;

        const foundCustomer = _.find(customers, { _id: customer.value });

        if (foundCustomer?.isAlarmCenter) {

          delete query['customer._id'];
          query = { ...query, 'alarmCenter._id': customer.value };

        }

      }

    }

    if (resource === 'problematic-alarms') {

      const result = await httpClient(`${apiUrl}/health/open-alarms/admin`, { method: 'POST' }, {
        secret: '',
        timePassed: 120,
        customerIds: [
          '62908a4b-0d8d-4787-975b-a854205aa99f',
          '98240644-af9a-4b8c-9952-b14a90748287',
          '500acf48-09ad-4b13-9a50-e8524ab0699e',
          '8eef9740-4a43-4404-b4d0-d53616194a32',
          '6b91bd42-33de-480f-960b-21d6ac0d117c',
          'bd4e5aa9-97f8-4a69-b121-471f34240769',
          '3fbe9370-3b73-424d-a139-625bc6b0899f',
          'aef49985-dabd-430d-aec6-20a454a28048',
          '78712634-f017-401d-9c0f-0d9b622e0ab7',
          'bd4e5aa9-97f8-4a69-b121-471f34240769',
          'b0dbd287-9960-42cc-9736-22df530efe0f',
          '782442ee-454d-44b4-9598-fbb80ebb33d7',
          'b2ab867a-503c-44bd-adef-38b1de1aca8d',
        ],
      });

      return {
        data: result.json.result.map((document: Alarm) => {

          return RecordNameAsRaXgacRecord(document, 'alarms');

        }),
        total: result.json.total,
      };

    }

    resource = afterTranslateResource(resource);

    let url = `${apiUrl}/${resource}?${queryString.stringify(query)}`;

    if (betweenFilter) {

      url = `${url}&createdAt>${betweenFilter[0]}&createdAt<${betweenFilter[1]}`;

    }

    if (filter.q && filter.q.length > 0 && resource !== 'users/without-app') {

      url = `${apiUrl}/${resource}/search?${queryString.stringify(query)}`;

    }

    if (resource === 'users' && filter.globalRoles?.includes('admin')) {

      url = `${url}&!isServiceAccount`;

    }

    const result = await httpClient(url).then(({ json }) => ({
      data: json.result.map((record: Record<string, unknown>) => (RecordNameAsRaXgacRecord(record, resource))),
      total: json.total,
    }));

    if (resource === 'customers') {

      if (result.data.responsePartners) {

        result.data.responsePartners = result.data.responsePartners.map((partner: Customer) => partner._id);

      }

    }
    if (resource === 'asset-groups') {

      result.data.assets = result.data.assets?.map((asset: Asset) => asset._id);

    }
    if (resource === 'assets') {

      result.data = result.data?.map((asset: any) => {

        asset.assetGroups = asset.assetGroups?.map((assetGroup: AssetGroupRef) => assetGroup._id);
        return asset;

      });

    }
    if (resource === 'zones') {

      result.data = result.data?.map((zone: any) => {

        if (zone.location?.coordinates?.[0]) {

          const coordinates = zone.location?.coordinates?.[0];

          const coordinatesLength = coordinates.length;
          if (coordinatesLength > 2 && _.isEqual(coordinates[coordinatesLength - 1], coordinates[coordinatesLength - 2])) {

            coordinates.pop();

          }

        }
        return zone;

      });

      if (params.meta?.size) {

        result.data = result.data.filter((zone: Zone) => {

          return turf.area(zone.location) > params.meta.size;

        });

      }

    }

    socketService.setActiveIdsPerResource(resource, result.data.map((record: Record<string, unknown>) => record._id));

    if (resource === 'specials/bhv-knop/device-list') {

      return {
        ...result,
        data: result.data.sort((a: Asset & { device: Device }, b: Asset & { device: Device }) => {

          if (a.device.type === 'loraGateway' && b.device.type === 'lora') {

            return 1;

          } if (a.device.type === 'lora' && b.device.type === 'loraGateway') {

            return -1;

          }
          return 0;

        }),
      };

    }
    return result;

  },

  getOne: async (resource, params) => {

    resource = preTranslateResource(resource);

    const result = await httpClient(`${apiUrl}/${resource}/${params.id}`);
    if (resource === 'assets') {

      result.json.hasApp = (result.json.app !== null);
      result.json.userId = result.json.user?._id || null;
      result.json.properties = {
        ...result.json.properties,
        image: result.json.properties?.image ? { title: 'image', src: result.json.properties.image } : undefined,

      };
      result.json.staticResponders = result.json.staticResponders.map((responder: StaticResponderRelation) => (
        { ...responder, staticResponder: responder.staticResponder._id }
      ));
      result.json.assetGroups = result.json.assetGroups.map((assetGroup: AssetGroupRef) => assetGroup._id);

      result.json.insideZones = result.json.insideZones.map((zone: Zone) => zone._id);

    }
    if (resource === 'users') {

      result.json.properties = {
        ...result.json.properties,
        image: result.json.properties?.image ? { title: 'image', src: result.json.properties.image } : undefined,
      };

    }
    if (resource === 'overlays') {

      result.json.imageUrl = result.json.imageUrl ? { title: 'image', src: result.json.imageUrl } : undefined;

    }

    if (resource === 'asset-groups') {

      result.json.assets = result.json.assets.map((record: Asset) => record._id);

    }
    if (resource === 'customers') {

      if (result.json.responsePartners) {

        result.json.responsePartners = result.json.responsePartners.map((partner: any) => partner._id);

      }

      result.json.billingContracts = result.json.billingContracts.map((contract: any) => {

        return { name: contract };

      });

      result.json.hasAlarmAgeLimit = !!result.json.preferences?.alarmAgeLimit;

      result.json.useDefaultMergeConfig = !result.json.preferences?.mergeConfig;

      if (result.json.kioskConfig) {

        result.json.hasKioskConfig = true;

      }
      result.json.kioskConfig = result.json.kioskConfig ? {
        ...result.json.kioskConfig,
        schedule: {
          ...result.json.kioskConfig.schedule,
          enabled: result.json.kioskConfig.schedule?.entries?.length > 0,
        },
        exposeAssets: result.json.kioskConfig?.exposeAssets?.map((asset: AssetRef) => asset._id),
        exposeLabels: result.json.kioskConfig?.exposeLabels?.map((asset: AssetRef) => asset._id),
        countLabelSeparately: result.json.kioskConfig?.exposeLabels?.[0]?.countLabel,
        alarm: result.json.kioskConfig?.alarm !== undefined ? {
          ...result.json.kioskConfig.alarm,
          audio: result.json.kioskConfig.alarm.focus?.red?.sound?.enabled || false,
          flash: result.json.kioskConfig.alarm.focus?.red?.flash || false,
          focus: result.json.kioskConfig.alarm.focus ? {
            ...result.json.kioskConfig.alarm.focus.red,
          } : undefined,
        } : undefined,
        homepage: result.json.kioskConfig.homepage ? {
          ...result.json.kioskConfig?.homepage,
          logo: result.json.kioskConfig?.homepage?.logo ? { title: 'image', src: result.json.kioskConfig.homepage.logo } : undefined,
        } : undefined,
      } : undefined;

    }
    if (resource === 'beacons') {

      result.json.codes = result.json.codes.map((code:string) => {

        return { name: code };

      });

    }

    if (resource === 'zones') {

      result.json.staticResponders = result.json.staticResponders.map((responder: StaticResponderRelation) => (
        { ...responder, staticResponder: responder.staticResponder._id }
      ));

      result.json.hasAddressPosition = !!result.json.location?.properties?.address?.formattedAddress;

      if (result.json.location?.coordinates?.[0]) {

        const coordinates = result.json.location?.coordinates?.[0];

        const coordinatesLength = coordinates.length;
        if (_.isEqual(coordinates[coordinatesLength - 1], coordinates[coordinatesLength - 2])) {

          coordinates.pop();

        }

      }

    }
    if (resource === 'specials/kiosk-user') {

      result.json.device.kioskConfig = {
        ...result.json.device.kioskConfig,
        screenSchedule: {
          ...result.json.device.kioskConfig.schedule,
          enabled: result.json.device.kioskConfig.schedule?.entries?.length > 0,
        },
        exposeAssets: result.json.device.kioskConfig.exposeAssets?.map((asset: AssetRef) => asset._id),
        exposeLabels: result.json.device.kioskConfig.exposeLabels?.map((asset: AssetRef) => asset._id),
        countLabelSeparately: result.json.device.kioskConfig?.exposeLabels?.[0]?.countLabel,
        alarm: result.json.device.kioskConfig?.alarm !== undefined ? {
          ...result.json.device.kioskConfig.alarm,
          audio: result.json.device.kioskConfig.alarm.focus?.red?.sound?.enabled || false,
          flash: result.json.device.kioskConfig.alarm.focus?.red?.flash || false,
          focus: result.json.device.kioskConfig.alarm.focus ? {
            ...result.json.device.kioskConfig.alarm.focus.red,
          } : undefined,
        } : undefined,
        homepage: {
          ...result.json.device.kioskConfig?.homepage,
          logo: result.json.device.kioskConfig?.homepage?.logo ? { title: 'image', src: result.json.device.kioskConfig.homepage.logo } : undefined,
        },
      };

    }
    if (resource === 'report-triggers') {

      result.json.config = {
        ...result.json.config,
        filter: {
          ...result.json.config?.filter,
          assets: result.json.config?.filter?.assets?.map((asset: AssetRef) => asset._id),
          assetGroups: result.json.config?.filter?.assetGroups?.map((assetGroup: AssetGroupRef) => assetGroup._id),
        },
        ...(result.json.config?.assets && { assets: result.json.config?.assets.map((asset: AssetRef) => asset._id) }),
        ...(result.json.config?.asset && { asset: result.json.config?.asset._id }),
        ...(result.json.config?.exclude && { exclude: result.json.config?.exclude.map((customer: CustomerRef) => customer._id) }),
        ...(result.json.config?.assetGroups && { assetGroups: result.json.config?.assetGroups.map((assetGroup: AssetGroupRef) => assetGroup._id) }),
      };

      result.json.channels = result.json.channels.map((channel: any) => {

        if (channel.type === 'email') {

          return {
            ...channel,
            address: channel.address || [],
            contactLists: channel.contactLists?.map((contactList: ContactListRef) => (contactList._id)),
          };

        } if (channel.type === 'sms') {

          return {
            ...channel,
            numbers: channel.numbers?.map((number: string) => {

              return { sms: number };

            }),
            contactLists: channel.contactLists?.map((contactList: ContactListRef) => (contactList._id)),
          };

        }
        return {
          ...channel,
        };

      });

    }
    const record = RecordNameAsRaXgacRecord(result.json, resource);
    return { data: record };

  },

  getMany: async (resource, params) => {

    let query: any;

    const mappedIds = params.ids.map((id: any) => {

      if (typeof id === 'string') {

        return id;

      }
      return id._id;

    });

    query = { _id: mappedIds };

    if (params.meta?.sort) {

      query = { ...query, $sort: params.meta.sort };

    }

    if (resource === 'customer-user-relations-by-user') {

      const currentCustomer = getCurrentCustomer();
      query = {
        'user._id': mappedIds.join(),
        'customer._id': currentCustomer?.value,
        ...(params.meta?.sort ? { $sort: params.meta.sort } : {}),
      };
      resource = 'customer-user-relations';

    }

    if (resource === 'app-users') {

      query = { ...query, _id: mappedIds, app: '!null' };

    }
    resource = preTranslateResource(resource);
    if (resource === 'responsePartners') {

      resource = 'customers';

    }
    if (resource === 'all-assets') {

      resource = 'assets';

    }
    const entitiesToReturn: any[] = [];
    const getEntities = async (offset: number) => {

      query._id = Array.isArray(query._id) ? query._id : [query._id];
      const ids = query._id.slice(offset, offset + 100);

      if (ids.length === 0) {

        return;

      }
      const max = ids.length > 100 ? 100 : ids.length;
      const slicedQuery = {
        ...query, _id: ids.join(','), $max: max,
      };

      const url = `${apiUrl}/${resource}?${queryString.stringify(slicedQuery)}`;

      const response = await httpClient(url);
      entitiesToReturn.push(...response.json.result);
      if (response.json.result.length > 0 && entitiesToReturn.length < params.ids.length) {

        await getEntities(offset + response.json.result.length);

      }

    };
    await getEntities(0);
    return {
      data: entitiesToReturn.map((record: Record<string, unknown>) => {

        return RecordNameAsRaXgacRecord(record, resource);

      }) as RaXgacRecord<any>[],
    };

  },

  getManyReference: async (resource, params) => {

    resource = preTranslateResource(resource);
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      $sort: JSON.stringify([field, order]),
      $offset: (page - 1) * perPage,
      $max: perPage,
    };

    const url = `${apiUrl}/${resource}?${params.target}=${params.id}&${queryString.stringify(query)}`;

    const referenceRequest = await httpClient(url).then(({ headers, json }) => ({
      data: json.result.map((record: any) => {

        return {
          id: record._id,
          ...record,
          ...(resource === 'customer-user-relations' ? {
            customerId: record.customer._id,
            userId: record.user._id,
            assetGroupRestrictions: record.assetGroupRestrictions?.map((assetGroup: any) => assetGroup._id),
          } : {}),
        };

      }),
      total: parseInt(headers.get('content-range')?.split('/').pop() || '0', 10),
    }));

    return referenceRequest;

  },

  create: async (resource, params) => {

    resource = preTranslateResource(resource);

    delete params.data.id;
    delete params.data._id;
    delete params.data.createdAt;
    if (!['customer-user-relations', 'zones', 'observation-triggers', 'report-triggers', 'contact-lists'].includes(resource)) {

      delete params.data.customer;

    }
    delete params.data.updatedAt;
    delete params.data.telluId;
    let image;

    if (resource === 'assets' || resource === 'users') {

      image = params.data.properties.image;
      params.data.properties.image = undefined;

    }
    if (resource === 'overlays') {

      image = params.data.imageUrl;
      params.data.imageUrl = null;

    }

    if (resource === 'assets' && params.meta?.needsUserCreation) {

      let userNameCheckError = null;
      await httpClient(`${apiUrl}/users/check-username`, {
        method: 'POST',
      }, params.data.username).catch((error) => {

        if (error.status === 409) {

          userNameCheckError = {
            data: {
              id: null,
              error: true,
              message: `Username ${params.data.username} already exists`,
            },
          };

        }

      });

      if (userNameCheckError) {

        return userNameCheckError;

      }

      const registerResult = await qrCodeDataProvider.registerUser(
        params.data.name,
        params.data.username,
        params.data.properties,
        params.data.externalId,
        params.data.available,
        false,
        params.meta.code,
      );

      if (registerResult && registerResult.status !== 200) {

        return {
          data: {
            id: null,
            error: true,
            message: registerResult.message,
          },
        };

      }
      const createdUserRequest = await httpClient(`${apiUrl}/users?${queryString.stringify({ username: params.data.username })}`);
      const createdUser = createdUserRequest.json.result[0];
      const createdAsset = await httpClient(`${apiUrl}/assets?${queryString.stringify({ 'user._id': createdUser._id })}`);
      if (params.data.isAdmin) {

        const currentCustomer = getCurrentCustomer();
        const customerUserRelationRequest = await httpClient(`${apiUrl}/customer-user-relations?${queryString.stringify({
          'customer._id': currentCustomer?.value, 'user._id': createdUser._id,
        })}`);
        const customerUserRelation = customerUserRelationRequest.json.result[0];
        if (customerUserRelation) {

          await xgacDataProvider.update('customer-user-relations', {
            id: customerUserRelation._id,
            data: {
              roles: [
                ...customerUserRelation.roles,
                'bhvk_admin',
              ],
            },
            previousData: customerUserRelation,
          });

        }

      }

      return {
        data: {
          id: createdAsset.json?.result[0]?._id || null,
          user: createdUser,
        },
      };

    }

    const currentCustomer = JSON.parse(sessionStorage.getItem('currentCustomer') || localStorage.getItem('lastSavedCustomer') || '{}');

    if (resource === 'users') {

      const roles = [...params.data.roles || []];
      delete params.data.roles;
      let userPostResult;
      if (_.isEqual(params.data.globalRoles, ['admin'])) {

        userPostResult = await httpClient(`${apiUrl}/${resource}`, {
          method: 'POST',
        }, { ...params.data });

        await httpClient(`${apiUrl}/customer-user-relations`, {
          method: 'POST',
        }, {
          user: { _id: userPostResult.json._id, _ref: 'User' },
          customer: { _id: currentCustomer.value, _ref: 'Customer' },
          assetGroupRestrictions: params.data.assetGroupRestrictions?.map((assetGroup: string): AssetGroupRef => ({ _id: assetGroup, _ref: 'AssetGroup' })),
          roles,
        });

      } else {

        try {

          userPostResult = await httpClient(`${apiUrl}/users/with-relation`, {
            method: 'POST',
          }, {
            user: params.data,
            relation: {
              customer: { _id: currentCustomer.value, _ref: 'Customer' },
              assetGroupRestrictions:
                  params.data.assetGroupRestrictions?.map((assetGroup: string): AssetGroupRef => ({ _id: assetGroup, _ref: 'AssetGroup' })) || [],
              roles,
            },
          });

        } catch (error: any) {

          if (error.status === 409) {

            throw new Error('Conflict');

          }
          throw error;

        }

      }

      if (image?.rawFile) {

        await sendPicture(image.rawFile, userPostResult.json._id, 'users');

      }

      return { data: { ...userPostResult.json, id: userPostResult.json._id } };

    }
    if (resource === 'customers') {

      const redOrangeTemplateId = params.data.hasAlarmCenter
        ? ATS_CUSTOMER_LINK_RED_ORANGE_WITH_ALARMCENTER_TEMPLATE_ID
        : ATS_CUSTOMER_LINK_RED_ORANGE_WITHOUT_ALARMCENTER_TEMPLATE_ID;
      delete params.data.hasAlarmCenter;

      const customerCreateResult: { data: any } = await httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
      }, params.data).then(({ json }) => ({
        data: { ...params.data, id: json._id },
      }));

      if (!customerCreateResult?.data?.id) {

        throw new Error('Customer create failed');

      }
      const promises = [];

      promises.push(...customerCreateDefaults(customerCreateResult?.data?.id, redOrangeTemplateId, customerCreateResult?.data?.timezone));

      await Promise.allSettled(promises);

      return customerCreateResult;

    }

    if (resource === 'customer-user-relations') {

      const createResult = await httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
      }, {
        customer: { _id: params.data.customer._id, _ref: 'Customer' },
        user: { _id: params.data['user._id'], _ref: 'User' },
        assetGroupRestrictions: params.data.assetGroupRestrictions?.map((assetGroup: string): AssetGroupRef => ({ _id: assetGroup, _ref: 'AssetGroup' })),
        roles: params.data.roles,
      }).then(({ json }) => ({
        data: { ...params.data, id: json._id },
      }));

      return createResult;

    }

    // Only occurs when creation happens by means of cloning
    if (typeof params.data.customer === 'object') {

      params.data.customer = params.data.customer._id;

    }

    params.data = toXgacCreateDto(params.data, resource);
    const createResult = await httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
    }, { ...params.data, customer: { _id: params.data.customer || currentCustomer.value, _ref: 'Customer' } }).then(({ json }) => ({
      data: { ...json, id: json._id },
    }));

    if (image && (resource === 'assets' || resource === 'overlays')) {

      await sendPicture(image.rawFile, createResult.data.id, resource);

    }

    return createResult;

  },

  update: async (resource, params) => {

    delete params.data.hasAlarmCenter;
    const id = params.data._id || params.data.id;

    params.data = updateComparison(params.data, params.previousData);

    // This line is added to prevent empty object to be sent to the backend.
    // This can occur when multiple resources can be edited on one page, and not all contain changes.
    if (_.isEqual(params.data, {})) {

      return { data: params.previousData };

    }

    resource = preTranslateResource(resource);
    if (resource !== 'customer-user-relations' && resource !== 'customer-alarm-center-relations') {

      delete params.data.customer;

    } else {

      delete params.data.user;

    }

    if (resource === 'overlays') {

      if (params.data.imageUrl?.rawFile) {

        const imageUrl = await sendPicture(params.data.imageUrl.rawFile, id, resource);
        params.data.imageUrl = imageUrl;

      } else {

        params.data.imageUrl = params.data.imageUrl.src;

      }

    }
    if (resource === 'specials/kiosk-user') {

      if (params.data.device?.kioskConfig?.homepage?.logo) {

        if (params.data.device.kioskConfig.homepage.logo.rawFile) {

          const imageUrl = await sendPicture(params.data.device.kioskConfig.homepage.logo.rawFile, params.data.asset._id);
          params.data.device.kioskConfig.homepage.logo = imageUrl;

        } else {

          params.data.device.kioskConfig.homepage.logo = params.data.device.kioskConfig.homepage.logo.src;

        }

      }

    }

    if (resource === 'customers') {

      if (params.data.kioskConfig?.homepage?.logo) {

        if (params.data.kioskConfig.homepage.logo.rawFile) {

          const imageUrl = await sendPicture(params.data.kioskConfig.homepage.logo.rawFile, id);
          params.data.kioskConfig.homepage.logo = imageUrl;

        } else {

          params.data.kioskConfig.homepage.logo = params.data.kioskConfig.homepage.logo.src;

        }

      }

    }

    if (resource === 'assets' || resource === 'users' || resource === 'app-users') {

      if (params.data.properties?.image) {

        if (params.data.properties?.image?.rawFile) {

          const imageUrl = await sendPicture(params.data.properties.image.rawFile, id, resource);
          params.data.properties.image = imageUrl;

        } else {

          params.data.properties.image = params.data.properties.image.src;

        }

      }

    }
    if (resource === 'users') {

      if (params.data.username === params.previousData?.username) {

        delete params.data.username;

      }

    }
    if (resource === 'customer-user-relations') {

      params.data = {
        ...(params.data.customer?._id ? { customer: { _id: params.data.customer._id, _ref: 'Customer' } } : {}),
        ...(params.data['user._id'] ? { user: { _id: params.data['user._id'], _ref: 'User' } } : {}),
        ...(params.data.assetGroupRestrictions
          ? {
            assetGroupRestrictions: params.data.assetGroupRestrictions?.map((assetGroup: string): AssetGroupRef => (
              { _id: assetGroup, _ref: 'AssetGroup' }
            )),
          }
          : {}),
        roles: params.data.roles,
      };

    }

    params.data = toXgacUpdateDto(params.data, resource);

    const result = httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
    }, { ...params.data }).then(({ json }) => ({ data: { ...json, id: json._id } })).catch((error) => {

      if (resource === 'users' && error.status === 409) {

        throw new Error('Conflict');

      }
      throw error;

    });

    return result;

  },
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  updateMany: (resource, params) => {

    const updatePromises = [];
    params.data = toXgacUpdateDto(params.data, resource);
    for (const id of params.ids) {

      updatePromises.push(httpClient(`${apiUrl}/${resource}/${id}`, {
        method: 'PATCH',
      }, params.data).then(({ json }) => ({ data: json })));

    }

    return Promise.allSettled(updatePromises);

  },

  delete: (resource, params) => {

    if (resource === 'app-users') {

      return httpClient(`${apiUrl}/assets/app-user`, {
        method: 'DELETE',
      }, { assetId: params.id, deleteAllDevices: params.meta?.deleteAllDevices || false }).then(({ json }) => ({ data: json }));

    }
    resource = preTranslateResource(resource);
    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));

  },

  deleteMany: async (resource, params): Promise<any> => {

    if (resource !== 'app-users') {

      resource = preTranslateResource(resource);

    }
    const deletedIds: Identifier[] = [];
    for (const currentId of params.ids) {

      try {

        if (resource === 'app-users') {

          await httpClient(`${apiUrl}/assets/app-user`, {
            method: 'DELETE',
          }, { assetId: currentId, deleteAllDevices: params.meta.deleteAllDevices || false }).then(({ json }) => ({ data: json }));

        } else {

          await httpClient(`${apiUrl}/${resource}/${currentId}`, {
            method: 'DELETE',
          });

        }
        deletedIds.push(currentId);

      } catch (e) {

        console.log({ message: `failed to delete ${currentId}`, e });

      }

    }

    return { data: deletedIds };

  },

  subscribe: (topic: string, subscriptionCallback: SubscriptionCallback) => {

    socketService.subscribe(topic, subscriptionCallback);

    return Promise.resolve({ data: null });

  },

  unsubscribe: (topic: string) => {

    socketService.unsubscribe(topic);
    return Promise.resolve({ data: null });

  },

  getAlarmCenters: async (id:string) => {

    const relationRequest = await httpClient(`${apiUrl}/customer-alarm-center-relations/populated/?customer._id=${id}`);
    return relationRequest.json.result.map((relation: { customer: Customer; alarmCenter: Customer }) => {

      return relation.alarmCenter.name;

    });

  },

  moveAppUsers: async (assetIds: string[], newCustomer: string, oldCustomerId: string) => {

    const postData = {

      ids: assetIds,
      fromCustomer: { _id: oldCustomerId, _ref: 'Customer' },
      toCustomer: { _id: newCustomer, _ref: 'Customer' },

    };

    const updateResults = await httpClient(`${apiUrl}/assets/move`, {
      method: 'POST',
    }, postData);
    const statuses = updateResults.json.map((result: any) => {

      if (!result || result.status === 'rejected') return 400;

      return 200;

    });

    return statuses;

  },
  getRolesForUser: async (id: string) => {

    const customer = JSON.parse(sessionStorage.getItem('currentCustomer') || localStorage.getItem('lastSavedCustomer') || '');

    return httpClient(`${apiUrl}/customer-user-relations?${queryString.stringify({ 'user._id': id, 'customer._id': customer.value })}`).then(({ json }) => (
      { data: json.result[0].roles }));

  },

  getAppUsers: async (customerIds: Array<string>) => {

    const query = {
      'customer._id': customerIds.join(),
      app: '!null',
    };
    return httpClient(`${apiUrl}/assets?${queryString.stringify(query)}`, {
      method: 'GET',
    });

  },

  syncTree: async () => {

    await httpClient(`${apiUrl}/customers/62908a4b-0d8d-4787-975b-a854205aa99f`, { method: 'PATCH' }, { name: '06.1 Development' });

  },

  getBeaconsForZone: async (zoneId:string) => {

    const beaconRequest = await httpClient(`${apiUrl}/beacons?${queryString.stringify({ 'zone._id': zoneId })}`, { method: 'GET' });
    if (!beaconRequest.json) {

      return { total: 0 };

    }
    return beaconRequest.json || { total: 0 };

  },

  massAcknowledge: async (comment: string, ids?: string[]) => {

    return httpClient(`${apiUrl}/alarms/mass-acknowledge`, { method: 'POST' }, {
      customer: { _id: getCurrentCustomer()?.value, _ref: 'Customer' },
      comment,
      ...(ids ? { alarmIds: ids } : {}),
    });

  },

  updateAssetsInGroup: async (payload: any) => {

    return httpClient(`${apiUrl}/asset-groups/mass-update`, { method: 'POST' }, payload);

  },

  getAll: async (resource: string, params: {
    sort: SortPayload;
    filter: any;
    meta?: any; }) => {

    let total = null;
    const resultData = [];
    let page = 1;
    let tries = 3;
    while ((total === null || resultData.length < total) && tries > 0) {

      const iterationResult = await xgacDataProvider.getList(resource, { ...params, pagination: { page, perPage: 100 } });
      if (total === null) total = iterationResult.total || null;
      if (iterationResult.data.length === 0 && tries > 0) {

        tries--;
        continue;

      } else if (iterationResult.data.length > 0) {

        tries = 3;

      }
      resultData.push(...iterationResult.data);
      page++;

    }
    return { data: resultData, total };

  },

  massMoveDevices: async (selectedIds: Identifier[], newCenterPosition: PointGeometry) => {

    const circlePositions = selectedIds.length > 1 ? getPointsAroundPosition(selectedIds.length, newCenterPosition) : [newCenterPosition.coordinates];
    const updatePromises = [];
    for (let i = 0; i < selectedIds.length; i++) {

      updatePromises.push(xgacDataProvider.update('assets', {
        previousData: undefined,
        id: selectedIds[i],
        data: {
          position: { ...newCenterPosition, coordinates: circlePositions[i] },
        },
      }));

    }
    const promiseResults = await Promise.allSettled(updatePromises);
    if (promiseResults.some((result) => result.status === 'rejected')) {

      throw new Error('Failed to move devices');

    }
    return { data: null };

  },

};
