import React, { useEffect, useState } from 'react';
import {
  Button,
  EditButton,
  Labeled,
  ListContextProvider,
  Loading,
  Title,
  useCheckAuth,
  useList,
  useNotify,
  useTranslate,
  Datagrid,
  FieldProps,
  useRecordContext,
} from 'react-admin';
import Grid2 from '@mui/material/Unstable_Grid2';
import {
  Card,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  TextField as MuiTextField,
  Select as MuiSelect,
  MenuItem,
  Typography,
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DownloadIcon from '@mui/icons-material/Download';
import { get } from 'lodash';
import { reportDataProvider } from '../../../dataProviders/reportDataProvider';
import SimpleChipListField from '../../../components/fields/SimpleChipListField';
import ReferenceFieldNullable from '../../../components/fields/ReferenceFieldNullable';
import DateFieldNullable from '../../../components/fields/DateFieldNullable';
import { timeGranularityType } from '../../../lib/constants/selectChoices';
import { HasRoles } from '../../../components/HasRoles';
import TextFieldNullable from '../../../components/fields/TextFieldNullable';

const Grid = Grid2;

const BeaconCountGridItem = (props: { count: number; total: number; subTitle: string; primary: boolean }) => {

  const translate = useTranslate();
  return (
    <Grid md={6}>
      <Card>
        <CardContent>
          <>
            {props.count} / {props.total}
            <p>
              {translate(props.subTitle)}
            </p>
          </>
        </CardContent>
      </Card>
    </Grid>
  );

};

const BeaconBattery = (props: FieldProps) => {

  const record = useRecordContext();
  if (!record || !props.source) {

    return <TextFieldNullable/>;

  }
  const value = get(record, props.source);
  if (!value) {

    return <TextFieldNullable/>;

  }
  return <span>{`${value}%`}</span>;

};

const BeaconTableList = (props: { beacons: any[]; title: string; lastSeen?: boolean }) => {

  const translate = useTranslate();
  const listContext = useList({
    data: props.beacons.map((beacon) => {

      return { ...beacon, id: beacon._id };

    }),

  });

  return (
    <Card sx={{ margin: '10px', width: '100%' }}>
      <CardHeader title={translate(props.title)}/>
      <CardContent>
        <ListContextProvider value={listContext}>
          <Datagrid bulkActionButtons={false} style={{ tableLayout: 'fixed', width: '100%' }} rowClick={false} resource="beacons">
            <SimpleChipListField source="codes" label="resources.beacons.fields.codes"/>
            <ReferenceFieldNullable source="zone._id" reference="zones" label="resources.beacons.fields.zone._id"/>
            {props.lastSeen ? (
              <DateFieldNullable source="state.lastSeenAt" showTime={true} label="resources.beacons.fields.state.lastSeenAt"/>
            ) : (
              <BeaconBattery source={'state.battery'} label="resources.devices.fields.battery"/>
            )};
            <HasRoles anyOf={['admin']}>
              <EditButton resource="beacons"/>
            </HasRoles>
          </Datagrid>
        </ListContextProvider>
      </CardContent>
    </Card>
  );

};
export const UnhealthyBeaconReport = () => {

  const checkAuth = useCheckAuth();
  checkAuth();
  const [reportResult, setReportResult] = useState<any>(null);
  const [lastSeenThreshold, setLastSeenThreshold] = useState<{ type: string; value: number }>(
    { type: 'day', value: 1 },
  );
  const [lastSeenThresholdType, setLastSeenThresholdType] = useState<string>('day');
  const [lastSeenThresholdValue, setLastSeenThresholdValue] = useState<number>(1);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const translate = useTranslate();
  const notify = useNotify();

  useEffect(() => {

    if (!lastSeenThreshold) {

      return;

    }
    const getReport = async () => {

      const report = await reportDataProvider.getUnhealthyBeacons(lastSeenThreshold);
      setReportResult(report);

    };

    getReport();

  }, [lastSeenThreshold]);

  useEffect(() => {

    const localLastSeenThreshold = localStorage.getItem('lastSeenThreshold');
    if (localLastSeenThreshold) {

      setLastSeenThreshold(JSON.parse(localLastSeenThreshold));
      setLastSeenThresholdType(JSON.parse(localLastSeenThreshold).type);
      setLastSeenThresholdValue(JSON.parse(localLastSeenThreshold).value);

    }

  }, []);

  const saveSettings = () => {

    setLastSeenThreshold({ type: lastSeenThresholdType, value: lastSeenThresholdValue });
    localStorage.setItem('lastSeenThreshold', JSON.stringify({ type: lastSeenThresholdType, value: lastSeenThresholdValue }));
    setSettingsOpen(false);

  };

  const downloadAttachments = () => {

    if (!reportResult.emailAttachments || reportResult.emailAttachments?.length === 0) {

      notify('resources.unhealthyBeacons.text.noAttachments', { type: 'warning' });
      return;

    }

    reportDataProvider.getAttachmentForReport(reportResult.emailAttachments, document);

  };

  const closeDialog = () => {

    setSettingsOpen(false);
    setLastSeenThresholdValue(lastSeenThreshold.value);
    setLastSeenThresholdType(lastSeenThreshold.type);

  };

  if (!reportResult) {

    return <Loading/>;

  }

  if (reportResult.value?.totalBeaconCount === 0) {

    return (
      <div style={{ height: '100%' }}>
        <Title title="resources.report-triggers.text.config_types.unhealthyBeacons"/>
        <Card sx={{ height: '100%' }}>
          <CardContent>
            <div style={{ textAlign: 'center' }}>
              <Typography variant={'h6'}>{translate('resources.unhealthyBeacons.text.noneFound')}</Typography>
            </div>
          </CardContent>
        </Card>
      </div>
    );

  }

  return (
    <div>
      <Title title="resources.report-triggers.text.config_types.unhealthyBeacons"/>
      <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <IconButton onClick={() => setSettingsOpen(true)}
        >
          <SettingsIcon/>
        </IconButton>
        { reportResult.emailAttachments && (
          <IconButton onClick={downloadAttachments}>
            <DownloadIcon/>
          </IconButton>
        )}
      </div>

      <Grid container spacing={2}>
        <BeaconCountGridItem count={reportResult.value?.lowBattery.length}
          total={reportResult.value?.totalBeaconCount}
          subTitle="resources.unhealthyBeacons.text.lowBattery.label"
          primary={false}
        />
        <BeaconCountGridItem count={reportResult.value?.inactive.length}
          total={reportResult.value?.totalBeaconCount}
          subTitle="resources.unhealthyBeacons.text.inactive.label"
          primary={true}
        />
        <BeaconTableList beacons={reportResult.value?.inactive} title="resources.unhealthyBeacons.text.inactive.label"/>
        <BeaconTableList beacons={reportResult.value?.lowBattery} title="resources.unhealthyBeacons.text.lowBattery.label" lastSeen={true}/>
      </Grid>
      <Dialog open={settingsOpen} onClose={closeDialog}>
        <DialogContent>
          <Labeled label="resources.report-triggers.fields.config.last_seen_threshold">
            <>
              <div>
                <MuiTextField defaultValue={lastSeenThreshold.value} onChange={(e) => {

                  setLastSeenThresholdValue(parseInt(e.target.value, 10));

                }} type="number"
                label={translate('resources.report-triggers.fields.time_granularity.value')}
                value={lastSeenThresholdValue}
                size="small"
                sx={{ marginTop: '8px' }}
                />
                <MuiSelect defaultValue={lastSeenThreshold.type}
                  size="small"
                  onChange={(e) => {

                    setLastSeenThresholdType(e.target.value);

                  }}
                  label={translate('resources.report-triggers.fields.time_granularity.type')}
                  value={lastSeenThresholdType}
                  sx={{ marginTop: '8px' }}

                >
                  {timeGranularityType.map((choice) => {

                    return (<MenuItem value={choice.id}>{translate(choice.name)}</MenuItem>);

                  })}
                </MuiSelect>
              </div>
            </>
          </Labeled>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog} autoFocus>
            <>{translate('general.text.close')}</>
          </Button>
          <Button onClick={saveSettings} label={translate('ra.action.save')}/>
        </DialogActions>
      </Dialog>
    </div>
  );

};
